import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Button } from '@mui/material';
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import { useAuthContext } from '../../context/AuthContext';
import "./Header.css";
import Menu from "./Menu";

function Header({isTop}) {  
  console.log("無限ループチェック:Header");
  var isLoggedin = false;
  const { me, myInfo } = useAuthContext();
  if (me) {
    isLoggedin = true;
  }else {
    isLoggedin = false;
  };
  const navigate = useNavigate();
  const handleUserPage = () => {
    if (me) {
      navigate("/");
    }
  };
  const handleLogout = () => {
    signOut(auth);
    navigate("/login");
  };
  return (
    <div className='header'>
      <div className="header_icon">
        <Link to="/" className="header_mysoulIconLink">
          {!isTop && <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2FRecordPlanet_icon.png?alt=media&token=950abcf7-154d-4438-8531-d72091654bbe" className="header_mysoulIcon" />}
        </Link>        
      </div>
      <nav className="header_nav">
        <ul className="header_links">
          <li className="header_link">
              <Link to="/about" className="header_linkto">記録アプリって何？</Link>
          </li>
          {/* <li className="header_link">
              <Link to="/review" className="header_linkto">レビュー</Link>
          </li> */}
          <li className="header_link">
              <Link to="/addApp" className="header_linkto">アプリ登録</Link>
          </li>
        </ul>
      </nav>
      <div className="header_right">
        {/* ユーザーアイコン */}
        {/* {isLoggedin && <Avatar className="header_userIcon" src={myInfo.avatar} onClick={handleUserPage}/>} */}

        <div className="header_login">
          {/* ログインボタン */}
          {!isLoggedin && <Link to="/login" className="header_loginLink"><Button variant="contained" className="header_loginButton">ログイン</Button></Link>}
          {/* ログアウトボタン */}
          {isLoggedin && <Button variant="contained" className="header_loginButton" onClick={handleLogout}>ログアウト</Button>}
        </div>
      </div>
      <div className="header_burger">
        <Menu width={250}/>            
      </div>
    </div>
  )
}

export default Header
