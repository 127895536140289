import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button } from '@mui/material';
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import "./Menu.css"
import { useAuthContext } from '../../context/AuthContext';

export default props => {
    var isLoggedin = false;
    const { me, myInfo } = useAuthContext();
    if (me) {
      isLoggedin = true;
    }else {
      isLoggedin = false;
    };

    const navigate = useNavigate();
    const handleLogout = () => {
        signOut(auth);
        navigate("/login");
    };
    return (
        <Menu {...props}>
            <div className="menu_user">
                {isLoggedin && <Avatar className="header_userIcon" src={myInfo.avatar} /*onClick={handleUserPage}*//>}
                {isLoggedin && myInfo.accountName}

            </div>
            <div className="menu_login">
                {/* ログインボタン */}
                {!isLoggedin && <Link to="/login" className="header_loginLink"><Button variant="contained" className="header_loginButton">ログイン</Button></Link>}
                {/* ログアウトボタン */}
                {isLoggedin && <Button variant="contained" className="header_loginButton" onClick={handleLogout}>ログアウト</Button>}
            </div>


            <Link to="/" className="menu-item" >
                Top
            </Link>

            <Link to="/about" className="menu-item" >
            記録アプリって何？
            </Link>

            {/* <Link to="/review" className="menu-item" >
            レビュー
            </Link> */}

            <Link to="/addApp" className="menu-item" >
            アプリ登録
            </Link>
        </Menu>
    );
};