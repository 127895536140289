import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material'

import "./AppThumb.css"

const AppThumb = forwardRef(
    ({name, url, logo, category, outline, appId}, ref) => {
        const navigate = useNavigate();
        const appUrl = "/apps/" + appId;
        const handleEventsDetail = () => {
            if(appId) {
                navigate(appUrl);
            }
        };
        return (
            <div className="tokenThumb" ref={ref}>
                <div className="tokenThumb_body" onClick={handleEventsDetail}>
                    <Avatar src={logo} />
                    <div className="tokenThumb_eventInfo">
                        <p className="tokenThumb_eventTitle">{name}</p>
                        <p className="tokenThumb_eventDate">{outline}</p>
                        {/* <p className="tokenThumb_eventPlace">@{category}</p> */}
                        {/* <a target="_blank" href={url} className="tokenThumb_url">{url}</a> */}
                    </div>
                </div>
            </div>
        )
    }
)
      
export default AppThumb
