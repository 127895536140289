import { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [me, setUser] = useState('');
  const [myInfo, setMyInfo] = useState('');

  const value = {
    me,
    myInfo
  };

  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth, (user) => { 
      if (user)
      {
        const docRef = doc(db, "users", user.uid);
        getDoc(docRef).then((documentSnapshot) => {
          setMyInfo(documentSnapshot.data());   
        });
        setUser(user);
      };
    });
    return () => {
      unsubscribed();
    };
  }, []);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
  
