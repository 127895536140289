import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import './SearchBox.css';
import { Button } from '@mui/material';

function SearchBox({placeholder}) {
    const navigate = useNavigate();
    const handleSearchPage = async (event) => {
        console.log("kensaku");
        event.preventDefault();
        const { keyword } = event.target.elements;
        navigate("/search/" + keyword.value);
      };
    return (
        <div className="search">
            <form onSubmit={handleSearchPage}>
                <div className='search__bar'>
                    <SearchIcon/>
                    <input
                        name="keyword"
                        className="search__bar__input"
                        placeholder={placeholder}
                    />
                </div>
            </form>
        </div>
      )
 }

 export default SearchBox;