import React, { useState, useEffect } from 'react';
import { MuiFileInput } from 'mui-file-input';
import Typography from '@mui/material/Typography';
import { db, auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"
import { useNavigate } from 'react-router-dom';
import { SendFile } from '../functions/SendFile';

const SignUp = () => {
  console.log("無限ループチェック:SignUp");
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState();

  //ファイルhandleChange関数
  const handleSetImage = (newFile) => {
    setImage(newFile);
  };
  
  //メモリ内のBLOBにアクセスするためのURL生成
  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image));
    }
  }, [image]);

  //アカウント作成
  const handleSubmit = async (event) => {
    event.preventDefault();        
    
    const { name, email, password } = event.target.elements;
    try {
      await createUserWithEmailAndPassword(auth, email.value, password.value).then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        //ユーザープロフィール画像アップロード
        const storagePath = "userProfileImage/" + user.uid + "/image";
        SendFile(image, storagePath).then((imageUri) => {
          updateProfile(auth.currentUser, {
            displayName: name.value, photoURL: imageUri
          }).then(() => {
            setDoc(doc(db, "users", user.uid), {
              accountName:name.value,
              userId: user.uid,
              avatar: imageUri,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              verified: false,
              introduction: "",
              wallet:"",
              followCount:0,
              followerCount:0,
              myPostCount:0,
            }).then(() => {
              navigate('/');
            });
          }).catch((error) => {
            setError(error.message);
          });
        });
      })
      .catch((error) => {
        setError(error.message);
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>ユーザ登録</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>ソウルネーム</label>
          <input name="name" type="text" placeholder="name" />
        </div> 
        <div>
          <label>メールアドレス</label>
          <input name="email" type="email" placeholder="email" />
        </div>       
        <div>
          <label>パスワード</label>
          <input name="password" type="password" placeholder="password" />
        </div>
        <div>
          {/* ファイル選択 UI */}
          <MuiFileInput value={image} onChange={handleSetImage} variant="outlined" accept="image/*" />
          <br />
          <Typography variant="caption" component="div" gutterBottom>Imageファイルのみ。</Typography>
          {(image) && !(image.type === "image/png" || image.type === "image/jpeg") && (
            <Typography variant="caption" component="div" color="error.main" gutterBottom>このファイルタイプはサポートしていません。</Typography>
          )}

          {/* プレビュー画面 */}
          {(image) && (image.type === "image/png" || image.type === "image/jpeg") && (
            <React.Fragment>
              <Typography variant="caption" component="div" mt={1} gutterBottom></Typography>
              <img id="preview" src={preview} alt="preview" className="previewimg" height="200"/>
            </React.Fragment>
          )}
        </div>
        <div>
          <button>登録</button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;