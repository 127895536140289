import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { Avatar, Button } from '@mui/material';

import { doc, getDocs, getDoc, collection, query, orderBy, where } from "firebase/firestore";
import { db } from "../firebase";

import './Category.css';
import { useAuthContext } from '../context/AuthContext';
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import AppThumb from '../components/token/AppThumb';
import SearchBox from '../components/SearchBox';

const Category = () => {
    console.log("無限ループチェック：Category");
    const [apps, setApps] = useState([]);
    const { me, myInfo } = useAuthContext();
    const params = useParams();
    
    useEffect(() => {
        const categoryId = params.categoryId;

        const strlength = categoryId.length;
        const strFrontCode = categoryId.slice(0, strlength - 1);
        const strEndCode = categoryId.slice(strlength - 1, categoryId.length);

        const startcode = categoryId;
        const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

        const col = collection(db, "apps");
        const q = query(col, where("category", "==", params.categoryId), orderBy("date"));
        getDocs(q).then((querySnapshot) => {
            setApps(querySnapshot.docs.map((doc) => {
            const data = doc.data();
            console.log(data);
            var app = {
                key:doc.id.trim(),
                category:data.category,
                name:data.name,
                url:data.url,
                logo:data.logo,
                outline:data.outline,
                appId:doc.id.trim()
            };
            return app;         
          }));
        });
    
      }, [me]);

    return (
        <div className="search">
            {/* Header */}
            <Header />
        
            {/* Sidebar */}
            {/* <Sidebar cur="1"/> */}
            
            <div className="search_element">                
                <div className="search_result">
                    <span>ジャンル『{params.categoryId}』</span>:{apps.length}件
                </div>
                <div className="search_eventList">
                    {apps.map((app) => (
                        <AppThumb
                            key={app.key}
                            name={app.name}
                            url={app.url}
                            logo={app.logo}
                            appId={app.appId}
                            category={app.category}
                            outline={app.outline}
                        />
                    ))}          
                </div>
            </div>
        </div>
    )
}
export default Category