// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { db } from '../firebase';
import { limit, getDocs, getDoc, collection, query, orderBy, serverTimestamp } from "firebase/firestore";

const Search = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('hashtag');

  useEffect(() => {
    const postData = collection(db, "posts");
    const q = query(postData, orderBy("timestamp", "desc"));
    getDocs(q).then((querySnapshot) => {      
        setPosts(querySnapshot.docs.map((doc) => ({ id:doc.id, ...doc.data() })));
    });
  }, []);

  const handleSearch = async () => {
    let query;

    if (searchType === 'hashtag') {
      query = db.collection('posts').where('hashtags', 'array-contains', searchTerm);
    } else {
      const searchDate = new Date(searchTerm);
      const nextDay = new Date(searchDate);
      nextDay.setDate(searchDate.getDate() + 1);

      query = db.collection('posts').where('timestamp', '>=', searchDate).where('timestamp', '<', nextDay);
    }

    const snapshot = await query.get();
    setPosts(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

    return (
        <div>
        <h1>Twitter-like Search</h1>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="例: #ハッシュタグ @ユーザー名 since:YYYY-MM-DD"
        />
        <button onClick={handleSearch}>検索</button>
        <div>
        </div>
      </div>
    );
};

export default Search;