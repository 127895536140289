import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

import { useAuthContext } from '../context/AuthContext';

const MakeWallet = () => {
  const [response, setResponse] = useState(null);
  const { me } = useAuthContext();

  useEffect(() => {
    if (me) {
      const callGCPFunction = async () => {
        try {
          const resp = await fetch('https://sbt-contract-1db65ff394fa89-7lfi5x2h.an.gateway.dev/createaddress', {
            method: 'GET'
          });
          const data = await resp.json();
          setResponse(data);
        } catch (error) {
          console.error(error);
        }
      };
      callGCPFunction();      
    }
  }, [me]);

  useEffect(() => {
    if (me) {
      updateDoc(doc(db, "users", me.uid), {
        wallet: response.address,
      });
    }
  }, [response])

  const navigate = useNavigate();
  const handleToMain = () => {
    navigate("/");
  };

  return (
    <div>
      <h1>Please store this phrase and key in a secure place.</h1>
      {response && <p>mnemonic: {JSON.stringify(response.mnemonic)}</p>}
      {response && <p>private key: {JSON.stringify(response.private_key)}</p>}
      {response && <p><Button variant="contained" className="submit" onClick={handleToMain}>OK</Button></p>}
    </div>
  );
};

export default MakeWallet;