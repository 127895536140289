import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { Avatar, Button } from '@mui/material';

import './About.css';
import Header from "../components/header/Header";
import TokenThumb from '../components/token/AppThumb';

const About = () => {
    console.log("無限ループチェック：About");


    return (
        <div className="about">
            {/* Header */}
            <Header />

            <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Frecord_planet_about%2FYuhei_The_background_image_of_a_beautiful_space_and_galaxy_twin_ad07f410-2eff-4a9e-b823-4ea63abe321c.jpg?alt=media&token=4b14c4b4-78f3-4915-8b85-292e866bc4a1" className="about_bg_image" />
            <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2FRecordPlanet_logo.png?alt=media&token=a22a3cf7-c10a-4821-a850-da95b03acef8" className="about_header_logo" />

            <div className="about_text">
                <h2>
                    記録アプリって何？
                </h2>
                <p>
                    私たちの人生には数え切れないほどの大切な瞬間や情報が存在しますが、時とともに記憶は薄れ、大切なことすら忘れてしまうことがあります。『記録アプリ』とは、大切な思い出や情報を記録し、忘れないようサポートするアプリケーションの総称です。
                </p>      
                <h2>
                    RecordPlanetの役割
                </h2>          
                <p>
                    『RecordPlanet』では、様々なニーズに合わせて厳選された記録アプリをご紹介します。日々の出来事を記録する日記アプリから、家族や友人との大切な思い出を共有できるフォトアルバムアプリ、さらにはビジネスシーンで役立つスケジュール管理やタスク管理アプリまであるので、きっとあなたの代わりに大切なことを記憶してくれるはずです。
                </p>
                <h2>
                    RecordPlanetでやりたいこと
                </h2>     
                <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fconcept%2Fconcept_1.png?alt=media&token=93cfd1e0-ce5d-4768-909e-473ae4293f85" className="" />
                <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fconcept%2Fconcept_2.png?alt=media&token=eebd368e-f367-4c60-86b6-656873fda0ad" className="" />
                <h3>
                    記録アプリを日常に
                </h3>
                <p>
                    　自分自身のデータベースを作る、記録行為の楽しさを知ってもらい誰もが記録アプリを日常的に使うような世界をつくる
                </p>
                <h3>
                    レコテクによる共有
                </h3>
                <p>
                    　レコテク(記録アプリのおすすめの使い方レビュー)を投稿し、より便利な記録アプリの使い方を共有する
                </p>
                <p>
                    これからの人生をより豊かにするために、ぜひ『RecordPlanet』で、あなたにぴったりのアプリを見つけてください。あなたの大切な記録が、これからもいつまでも輝き続けることを願っています。
                </p>
            </div>

        </div>
    )
}
export default About