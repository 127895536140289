import { doc, updateDoc, increment } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"


export const SendFile = async (filePath, storagePath) => {
  var fileUrl = "";
  // Create a reference to UploadFile
  const storageRef = ref(storage, storagePath);
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, filePath).then((snapshot) => {
    console.log('Uploaded a file!');
  }).catch((err) => {
    console.log(err);
  });

  await getDownloadURL(storageRef).then((url) => {
    console.log('getDownloadURL', url);
    fileUrl = url;
  })
  .catch((error) => {
    // Handle any errors
  });

  return fileUrl;
}

export const GiveToken = async (userId, tokenId, amount=1) => {
  await updateDoc(doc(db, "posts", tokenId), {
    followerCount: increment(-1)
  }).then(() => {

  }).catch((error) => {

  });
    return true;
}


export function GenerateUuid() {
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
      switch (chars[i]) {
          case "x":
              chars[i] = Math.floor(Math.random() * 16).toString(16);
              break;
          case "y":
              chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
              break;
      }
  }
  return chars.join("");
}