import { FavoriteBorder, QuestionAnswerOutlined, Redeem, ShareOutlined, VerifiedUser } from '@mui/icons-material'
import { Avatar, Button } from '@mui/material'
import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom';

import { doc, setDoc, getDoc, updateDoc, increment, collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import "./ReviewDetails.css"
import { useAuthContext } from '../../context/AuthContext';
import PersonalTokenDetails from './PersonalTokenDetails';

const ReviewDetails = forwardRef(
    ({appName, appLogo, appId, userName, userIcon, userId, description, date }, ref) => {
        const [dateString, setDateString] = useState("");
      
        useEffect(() => {
            if(date) {
                var eventTime = date.toDate();
                setDateString(eventTime.toLocaleString());
            }
        }, [date]);

        const navigate = useNavigate();
        const handleAppPage = () => {
            navigate("/apps/" + appId);
        };

        return (
            <div className="tokenDetails" ref={ref} onClick={handleAppPage}>
                <div className="tokenDetails_avatar">
                    <Avatar src={userIcon}/> 
                </div>
                <div className="tokenDetails_body">
                    <div className="tokenDetails_header">   
                        <div className="tokenDetails_headerText">
                            <p>{userName}</p>
                        </div>
                    </div>
                    <div className="tokenDetails_title">
                        <p>{appName}</p>
                    </div>
                    <div className="tokenDetails_description">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        )
    }
)

/**
 * UNIX TIME => MM-DD hh:mm
 **/
function getElapsedTime(curTime, pastTime){
    /*日時の差をミリ秒単位で取得*/
    var diffMilliSec = curTime - pastTime;
    /*ミリ秒を日数に変換*/
    var diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);
    if (diffDays < 1) {
        /*ミリ秒を時間数に変換*/
        var diffHours = parseInt(diffMilliSec / 1000 / 60 / 60);
        if (diffHours < 1) {
            /*ミリ秒を分数に変換*/
            var diffSecs = parseInt(diffMilliSec / 1000 / 60);   
            if (diffSecs < 1){ 
                return(
                    "たった今"
                );
            }         
            return(
                diffSecs + "分"
            );    
        }
        return(
            diffHours + "時間"
        );       
    }
    return(
        ("0" + (pastTime.getMonth() + 1)).slice(-2) + "/" +
        ("0" + pastTime.getDate()       ).slice(-2) + " " +
        ("0" + pastTime.getHours()      ).slice(-2) + ":" +
        ("0" + pastTime.getMinutes()    ).slice(-2)
    );
}  
      
export default ReviewDetails
