import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MuiFileInput } from 'mui-file-input';

import { doc, setDoc, getDoc, collection, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

import './Review.css';
import { useAuthContext } from '../context/AuthContext';
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import ReviewDetails from '../components/token/ReviewDetails';

const Review = () => {
    console.log("無限ループチェック：Review");
    const [error, setError] = useState('');
    const [image, setImage] = useState("");
    const [preview, setPreview] = useState();
    const [app, setApp] = useState([]);
    const { me, myInfo } = useAuthContext();
    const navigate = useNavigate();
    const params = useParams();
  
    useEffect(() => {    
      // トークン情報を取得
      const docRef = doc(db, "apps", params.appId);
      getDoc(docRef).then((documentSnapshot) => {
          setApp(documentSnapshot.data());
      });
    }, [])

    //ファイルhandleChange関数
    const handleSetImage = (newFile) => {
      setImage(newFile);
    };
    
    //メモリ内のBLOBにアクセスするためのURL生成
    useEffect(() => {
      if (image) {
        setPreview(URL.createObjectURL(image));
      }
    }, [image]);

    const handleAddReview = async (event) => {
        event.preventDefault();        
        
        const { description } = event.target.elements;
        try {
          console.log(description);
          const appRef = doc(collection(db, "posts"));
          setDoc(appRef, {
            appId: params.appId,
            appLogo: app.logo,
            appName: app.name,
            description: description.value,
            timestamp: serverTimestamp(),
            userIcon: myInfo.avatar,
            userId: myInfo.userId,
            userName: myInfo.accountName
          }).then(() => {
              navigate('/');
          });
        } catch (error) {
          setError(error.message);
        }
    };
    return (
        <div className="addevent">
            {/* Header */}
            <Header />
                
            <div className="addevent_element">
                <div className="addevent_body">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <form onSubmit={handleAddReview}>
                        <div className="addevent_body_form">
                            <h3>「こんな使い方がオススメ！」をみんなにシェアしよう</h3>
                            <p>(投稿できる最大文字数は128文字です)</p>
                            <textarea name="description" type="text" placeholder="アプリのおすすめ使い方を書く" maxLength="128" />
                        </div>
                        <div>
                            <button>投稿</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Review