import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Avatar, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MuiFileInput } from 'mui-file-input';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from "react-accessible-accordion";
  import "react-accessible-accordion/dist/fancy-example.css";

import { doc, setDoc, query, collection, where, serverTimestamp, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../firebase";

import './AddApp.css';
import { useAuthContext } from '../context/AuthContext';
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import ReviewDetails from '../components/token/ReviewDetails';

const AddApp = () => {
    console.log("無限ループチェック：AddEvent");
    const [error, setError] = useState('');
    const { me, myInfo } = useAuthContext();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [developer, setDeveloper] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [appInfo, setAppInfo] = useState('');
    const [categories, setCategories] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        getDocs(collection(db, "category")).then((categorySnapshot) => {
            setCategories(categorySnapshot.docs.map((doc) => {
                const data = doc.data();
                var categoryInfo = {
                  id: doc.id,
                  categoryName: data.name,
                };
                return categoryInfo; 
            }));
        }); 
    }, []);


    useEffect(() => {
        if (search.trim() !== '') {
        axios
            .get(`https://itunes.apple.com/search?term=${search}&media=software&country=jp&lang=ja_jp&limit=10`)
            .then((response) => {
            setResults(response.data.results);
            })
            .catch((error) => {
            console.error('Error fetching data:', error);
            });
        } else {
        setResults([]);
        }
    }, [search]);

    const handleSelectApp = async (appInfo) => {
        setDeveloper(appInfo.sellerName);
        setUrl(appInfo.sellerUrl);
        setSearch(appInfo.trackName);
        setDescription(appInfo.description);
        setAppInfo(appInfo);
    };

    const handleAddApp = async (event) => {
        event.preventDefault();        
        
        const { appName, developer, url, category } = event.target.elements;
        try {
            if (!appInfo.trackId){
                throw "itunesId is not set.";
            }            
            
            const q = query(collection(db, "apps"), where("itunesId", "==", appInfo.trackId));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs.length == 0){
                const appRef = doc(collection(db, "apps"));
                setDoc(appRef, {
                    itunesId: appInfo.trackId,
                    name:appName.value,
                    category: category.value,
                    company: developer.value,
                    date: serverTimestamp(),
                    releaseDate: new Date(appInfo.releaseDate),
                    currentVersionReleaseDate: new Date(appInfo.currentVersionReleaseDate),
                    price: appInfo.price,
                    outline:"",
                    url: url.value,
                    androidUrl: "",
                    iosUrl: appInfo.trackViewUrl,
                    description: appInfo.description,
                    logo: appInfo.artworkUrl100
                }).then(() => {
                    navigate('/');
                }); 
            }else{
                setError("このアプリケーションはすでに登録されています");
            }
        } catch (error) {
          setError(error);
        }
    };
    return (
        <div className="addapp">
            {/* Header */}
            <Header />
                
            <div className="addapp_element">
                <div className="addapp_body">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <form onSubmit={handleAddApp}>
                        <div>
                            <label>アプリ名</label>
                            <input name="appName" type="text" placeholder="Search for apps..." 
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>開発元</label>
                            <input name="developer" type="text" value={developer} placeholder="developer" readOnly />
                        </div>
                        <div>
                            <label>URL</label>
                            <input name="url" type="text" value={url} placeholder="url" readOnly />
                        </div>
                        <div>
                            <label>カテゴリ</label>
                            <select name="category">
                                {categories.map((category) => (
                                <option key={category.id} value={category.categoryName}>
                                    {category.categoryName}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <button>App追加</button>
                        </div>
                        <div style={{whiteSpace: 'pre-line'}}>
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>App概要</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>{description}</AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <ul>
                            {results.map((result) => (
                            <li key={result.trackId} onClick={() => handleSelectApp(result)} className="">
                                <img src={result.artworkUrl60} className="" />
                                {result.trackName}
                            </li>
                            ))}
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddApp