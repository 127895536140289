import { BrowserRouter, Routes, Route } from "react-router-dom";
import Search from "./pages/Search";
import Category from "./pages/Category";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import MakeWallet from "./pages/MakeWallet";
import Top from "./pages/Top";
import AddApp from "./pages/AddApp";
import Review from "./pages/Review";
import AppInfo from "./pages/AppInfo";
import About from "./pages/About";

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Top/>} />
                <Route path="/about" element={<About/>} />
                {/* <Route path="/search/:keyword" element={<Search/>} /> */}
                <Route path="/category/:categoryId" element={<Category/>} />
                <Route path="/apps/:appId" element={<AppInfo/>} />
                <Route path="/addApp" element={<AddApp/>} />
                <Route path="/review/:appId" element={<Review/>} />
                <Route path="/search" element={<Search/>} />

                <Route path="/login" element={<Login/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/createaccount" element={<MakeWallet />}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Routers