import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyA7_A43WHU3-TiDzovSpC53nOwtoE3xAQM",
    authDomain: "recordplanet-183e1.firebaseapp.com",
    projectId: "recordplanet-183e1",
    storageBucket: "recordplanet-183e1.appspot.com",
    messagingSenderId: "183655319760",
    appId: "1:183655319760:web:a921691b465a220d0f36d5",
    measurementId: "G-MPFZ5M2BC8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// get auth
export const auth = getAuth(app);

// Initialize Cloud Storage and get at reference to the service
export const storage = getStorage(app);