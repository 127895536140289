import React from 'react'
import SidebarOption from './SidebarOption';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MessageIcon from '@mui/icons-material/Message';
import "./Sidebar.css";
import { Email } from '@mui/icons-material';

function Sidebar({cur}) {
  const isActive = (text, icon, link, targetNo) => {
    if(targetNo == cur) {
      return <SidebarOption text={text} Icon={icon} link={link} active />;
    }else {
      return <SidebarOption text={text} Icon={icon} link={link} />;
    }
  };

  return (
    <div className='sidebar'>
        {/* ツイッターアイコン */}
        {/* <LocalFireDepartmentIcon className="sidebar_mysoulIcon"/> */}

        {/* オプション */}
        {isActive("マイページ", HomeIcon, "/", 1)}
        {isActive("チェックイン", NotificationsIcon, "/checkin", 2)}
        
        {/* ツイートボタン */}
        {/* <Button variant="contained" className="sidebar_engrave" fullWidth>発行する</Button> */}
    </div>
  )
}

export default Sidebar
