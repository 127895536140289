import { FavoriteBorder, QuestionAnswerOutlined, Redeem, ShareOutlined, VerifiedUser } from '@mui/icons-material'
import { Avatar, Button } from '@mui/material'
import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import { doc, setDoc, getDoc, updateDoc, increment, collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import "./AppDetail.css"
import { useAuthContext } from '../../context/AuthContext';
import PersonalTokenDetails from './PersonalTokenDetails';

const AppDetail = forwardRef(
    ({appName, appLogo, appId, appCompany, description, outline, url, iosUrl, androidUrl }, ref) => {
        const handleOfficialSite = () => {
            if(url) {
                window.open(url, '_blank');
            }
        };
        const handleAppStore = () => {
            if(iosUrl) {
                window.open(iosUrl, '_blank');
            }
        };
        const handleGooglePlay = () => {
            if(androidUrl) {
                window.open(androidUrl, '_blank');
            }
        };
        return (
            <div className="appDetail" ref={ref}>
                <div className="appDetail_header">
                    <img src={appLogo} className="appDetail_logo" />
                    <div className="appDetail_header_text">
                        <div className="appDetail_header_appName">
                            <p>{appName}</p>
                        </div>
                        <div className="appDetail_header_outline">
                            <p>{outline}</p>
                        </div>
                        <div className="appDetail_header_companyName">
                            <p>{appCompany}</p>
                        </div>
                        <div className="appDetail_header_official_url">
                            <span className="appDetail_header_store_btn"><Button variant="contained" className="appDetail_header_store_button" onClick={handleOfficialSite}>公式サイト</Button></span>
                        </div>
                        <div className="appDetail_header_store_url">
                            {iosUrl && <span className="appDetail_header_store_btn"><Button variant="contained" className="appDetail_header_store_button" onClick={handleAppStore}>iPhone/iPad版</Button></span>}
                        </div>
                        <div className="appDetail_header_store_url">
                            {androidUrl && <span className="appDetail_header_store_btn"><Button variant="contained" className="appDetail_header_store_button" onClick={handleGooglePlay}>Android版</Button></span>}
                        </div>
                    </div>
                </div>
                <div className="appDetail_body" style={{whiteSpace: 'pre-line'}}>
                    <Accordion allowZeroExpanded>
                        <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>App概要</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>{description}</AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        )
    }
)

/**
 * UNIX TIME => MM-DD hh:mm
 **/
function getElapsedTime(curTime, pastTime){
    /*日時の差をミリ秒単位で取得*/
    var diffMilliSec = curTime - pastTime;
    /*ミリ秒を日数に変換*/
    var diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);
    if (diffDays < 1) {
        /*ミリ秒を時間数に変換*/
        var diffHours = parseInt(diffMilliSec / 1000 / 60 / 60);
        if (diffHours < 1) {
            /*ミリ秒を分数に変換*/
            var diffSecs = parseInt(diffMilliSec / 1000 / 60);   
            if (diffSecs < 1){ 
                return(
                    "たった今"
                );
            }         
            return(
                diffSecs + "分"
            );    
        }
        return(
            diffHours + "時間"
        );       
    }
    return(
        ("0" + (pastTime.getMonth() + 1)).slice(-2) + "/" +
        ("0" + pastTime.getDate()       ).slice(-2) + " " +
        ("0" + pastTime.getHours()      ).slice(-2) + ":" +
        ("0" + pastTime.getMinutes()    ).slice(-2)
    );
}  
      
export default AppDetail
