import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Avatar, Button } from '@mui/material';

import { doc, getDocs, getDoc, collection, query, orderBy, where } from "firebase/firestore";
import { db } from "../firebase";

import './AppInfo.css';
import { useAuthContext } from '../context/AuthContext';
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import AppDetail from '../components/token/AppDetail';
import ReviewDetails from '../components/token/ReviewDetails';

const AppInfo = () => {
    console.log("無限ループチェック：AppInfo");
    const [app, setApp] = useState([]);
    const [appInfo, setAppInfo] = useState({});
    const [response, setResponse] = useState([]);
    const [posts, setPosts] = useState([]);
    const { me, myInfo } = useAuthContext();
    const params = useParams();
    
    useEffect(() => {    
        // トークン情報を取得
        const docRef = doc(db, "apps", params.appId);
        getDoc(docRef).then((documentSnapshot) => {
          setApp(documentSnapshot.data());
          try {
            // const link = "https://itunes.apple.com/lookup?country=JP&id=" + documentSnapshot.data().itunesId;
            const link = "https://itunes.apple.com/search?term="+ documentSnapshot.data().itunesId + "&media=software&country=jp&lang=ja_jp&limit=1";
            console.log(link);
            axios
            .get(link)
            .then((response) => {
              console.log(response);
              const data = response.data;
              if (data.resultCount == 1){
                setResponse(data.results[0]);
                var appInfo = {
                    price:data.results[0].formattedPrice,
                    genre:data.results[0].genre,
                    appLogo:data.results[0].artworkUrl512,
                    appCompany:data.results[0].artistName,
                    appName:data.results[0].trackName,
                    description:data.results[0].description,
                    url:data.results[0].sellerUrl,
                    iosUrl:data.results[0].trackViewUrl
                };
                setAppInfo(appInfo);
              }else{
                throw "resultCount is not 1";
              }  
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
          } catch (error) {
            console.error(error);
          }   
      });
    }, [])
    
    useEffect(() => {
        const postData = collection(db, "posts");
        const q = query(postData, where("appId", "==", params.appId), orderBy("timestamp", "desc"));
        getDocs(q).then((querySnapshot) => {      
            setPosts(querySnapshot.docs.map((doc) => {
            const data = doc.data();
            var post = {
              key:data.timestamp,
              timestamp:data.timestamp,
              userName:data.userName,
              userId:data.userId,
              userIcon:data.userIcon,
              appName:data.appName,
              appId:data.appId,
              appLogo:data.appLogo,
              description:data.description,
            };
            return post;        
          }));
        });
      }, [])
      
    const navigate = useNavigate();
    const handleReview = () => {
        navigate("/review/" + params.appId);
      };

    return (
        <div className="appInfo">
            {/* Header */}
            <Header />
        
            {/* Sidebar */}
            {/* <Sidebar cur="1"/> */}
            
            <div className="appInfo_element">
                <div className="appInfo_body">
                    <AppDetail
                        appName={appInfo.appName}
                        appLogo={appInfo.appLogo}
                        appCompany={appInfo.appCompany}
                        url={appInfo.url}
                        androidUrl={app.androidUrl}
                        iosUrl={appInfo.iosUrl}
                        description={appInfo.description}
                        outline={app.outline}
                    />
                                    
                    <div className="appInfo_addReview">
                        <Button variant="contained" className="appInfo_addReviewButton" onClick={handleReview}>レコテク投稿</Button>
                    </div>
                    <div className="appInfo_review">
                        <h3>みんなのレコテク</h3>
                        {posts.map((post) => (
                                <ReviewDetails
                                appName={post.appName}
                                appLogo={post.appLogo}
                                appId={post.appId}
                                userName={post.userName}
                                userIcon={post.userIcon}
                                userId={post.userId}
                                description={post.description}
                            />
                        ))}   
                    </div>   
                </div>
            </div>
        </div>
    )
}
export default AppInfo