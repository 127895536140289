import React, { useEffect, useState } from 'react'
import { Avatar, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

import { limit, getDocs, getDoc, collection, query, orderBy, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

import './Top.css';
import { useAuthContext } from '../context/AuthContext';
import ReviewDetails from '../components/token/ReviewDetails';
import SearchBox from '../components/SearchBox';
import CategoryDetails from '../components/CategoryDetails';
import Header from '../components/header/Header';

const Top = () => {
    console.log("無限ループチェック：UserPage");
    const [newPosts, setNewPosts] = useState([]);
    const [newApps, setNewApps] = useState([]);
    const { me, myInfo } = useAuthContext();
    const navigate = useNavigate();
    
    useEffect(() => {
        const postData = collection(db, "posts");
        const q = query(postData, orderBy("timestamp", "desc"), limit(3));
        getDocs(q).then((querySnapshot) => {      
            setNewPosts(querySnapshot.docs.map((doc) => {
            const data = doc.data();
            var post = {
              key:data.timestamp,
              timestamp:data.timestamp,
              userName:data.userName,
              userId:data.userId,
              userIcon:data.userIcon,
              appName:data.appName,
              appId:data.appId,
              appLogo:data.appLogo,
              description:data.description,
            };
            return post;        
          }));
        });

        const appData = collection(db, "apps");
        const q2 = query(appData, orderBy("date", "desc"), limit(3));
        getDocs(q2).then((querySnapshot) => {      
            setNewApps(querySnapshot.docs.map((doc) => {
            const data = doc.data();
            var app = {
              key:data.date,
              timestamp:data.date,
              name:data.name,
              appId: doc.id,
              itunesId:data.itunesId,
              logo:data.logo,
              category:data.category,
            };
            return app;        
          }));
        });
      }, [])


      
    const handleSelectApp = async (appId) => {
        navigate("/apps/" + appId);
    }

    return (
        <div className="top"> 
            <div className="top_header_header">
                <Header isTop="true" />
            </div>
       
            <div className="top_header">
                <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Foutdoor%2FYuhei_concept_art_of_a_hiking_lady_who_is_taking_a_photo_of_sky_220dd3e5-052c-4900-8ef4-bdd4fc9c3b85.png?alt=media&token=020db749-a0de-4e36-9f38-b35f21c6b83f" className="top_header_image" />
                <nav className="top_header_nav">
                    <ul className="top_header_links">
                        <li className="top_header_link">
                            <Link to="/about" className="top_header_linkto">記録アプリって何？</Link>
                        </li>
                        {/* <li className="top_header_link">
                            <Link to="/review" className="top_header_linkto">レビュー</Link>
                        </li> */}
                        <li className="top_header_link">
                            <Link to="/addApp" className="top_header_linkto">アプリ登録</Link>
                        </li>
                    </ul>
                </nav>
                <p className="top_header_copy">大切な思い出を守る、記録アプリ検索サイト</p>
                
                <Scroll to="newapp" smooth={true} duration={600}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2FRecordPlanet_logo.png?alt=media&token=a22a3cf7-c10a-4821-a850-da95b03acef8" className="top_header_logo" />
                </Scroll>
                
                {/* <p className="top_header_title">RecordPlanet</p> */}
                <div className="top_header_search"><SearchBox placeholder="アプリ名・カテゴリ・キーワード"/></div>
            </div>
            
            <div className="top_body">
                <div className="top_new_review">
                    <h3>新着レコテク</h3>
                    {newPosts.map((post) => (
                            <ReviewDetails
                            appName={post.appName}
                            appLogo={post.appLogo}
                            appId={post.appId}
                            userName={post.userName}
                            userIcon={post.userIcon}
                            userId={post.userId}
                            description={post.description}
                        />
                    ))}  
                    {newPosts.length == 0 && "新着レコテクはまだありません"} 
                </div>    

                <div className="top_new_app" id="newapp">
                    <h3>新着アプリ</h3>
                    <ul>
                        {newApps.map((app) => (
                            <li key={app.timestamp} onClick={() => handleSelectApp(app.appId)} className="top_new_app_list">
                                <img src={app.logo} className="" />
                                {app.name}
                                [{app.category}]
                            </li>   
                        ))}   
                    </ul>
                    {newApps.length == 0 && "新着アプリはまだありません"} 
                </div>   

                <div className="top_search_category">
                    <h3>カテゴリから探す</h3>
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Ftravel%2FYuhei_concept_art_of_a_traveling_lady_who_is_taking_a_photo_of__911a483e-39aa-4d41-929e-d17a9203976f.png?alt=media&token=850c2b08-cc73-4a88-b292-703faca78021"
                        category="旅行"
                        categoryId="travel"
                        description="旅行先でのイベント・宿"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Foutdoor%2FYuhei_concept_art_of_a_hiking_lady_who_is_taking_a_photo_of_sky_21744258-7c6f-4ab3-919e-743c681b1ffb.png?alt=media&token=244a1664-ed76-4929-a516-d6672236df7e"
                        category="アウトドア"
                        categoryId="outdoor"
                        description="キャンプ・山登り"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Ffood%2FYuhei_concept_art_of_two_girls_in_a_close_friendship_having_lun_85605c51-4ba2-44ad-8b2f-3e3f8f63b84b.png?alt=media&token=2f0ddeea-7d12-48ac-b154-12188afd87e6"
                        category="飲食"
                        categoryId="food"
                        description="食べ物"
                        count="10"
                    />  
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fhealthcare%2FYuhei_concept_art_of_a_back_view_of_an_athlete_who_is_recording_451dfa7b-11c6-455a-a73b-bc324d2b213d.png?alt=media&token=c50e3c72-ae1c-499b-927c-65edeb01957f"
                        category="健康管理"
                        categoryId="healthcare"
                        description="ダイエット・体調管理"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fstudy%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_studying_and__80d2269c-0e77-4cea-adb3-60d8eac7a0a5.png?alt=media&token=792a093c-9843-4e65-9b08-1e60a1caf293"
                        category="学習・勉強"
                        categoryId="study"
                        description="勉強"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fbook%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_reading_a_boo_d6587f60-b24e-4c80-9504-1ca07da744a3.png?alt=media&token=5c0b1468-38a0-4359-afbc-6028ebbc0010"
                        category="本"
                        categoryId="book"
                        description="書籍"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fdiary%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_writing_a_dic_b8a44c6a-b3c6-4fc0-a290-6d03e002d28f.png?alt=media&token=d267263f-0d5c-493c-ba7f-70219df12a21"
                        category="日記"
                        categoryId="diary"
                        description="日々の記録"
                        count="10"
                    />  
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fbook%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_reading_a_boo_7726dd41-376e-48c3-9e46-c690027f384d.png?alt=media&token=66d1700f-79a9-4892-b918-f8570e5b507e"
                        category="メモ"
                        categoryId="memo"
                        description="雑多な記録"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fcheckin%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_in_front_of_t_97a66aa5-e1a1-4c1a-b81e-8564b868f024.png?alt=media&token=2e3c9e73-7631-47d6-88d7-4d807a2c1cbe"
                        category="チェックイン"
                        categoryId="checkin"
                        description="チェックイン記録"
                        count="10"
                    />   
                    <CategoryDetails
                        categoryImage="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fother%2FYuhei_concept_art_of_a_back_view_of_a_girl_who_is_entering_a_ne_138e3473-cb4a-4969-959f-330f0bb865c9.png?alt=media&token=50460cbd-fd67-4472-9455-ae5772a78d77"
                        category="その他"
                        categoryId="other"
                        description="その他の記録"
                        count="10"
                    />   
                </div>
            </div>
        </div>
    )
}
export default Top