import { FavoriteBorder, QuestionAnswerOutlined, Redeem, ShareOutlined, VerifiedUser } from '@mui/icons-material'
import { Avatar, Button } from '@mui/material'
import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom';


import "./CategoryDetails.css"

const CategoryDetails = forwardRef(
    ({categoryImage, category, categoryId, description, count }, ref) => {
        const navigate = useNavigate();
        const handleCategoryPage = () => {
            navigate("/category/" + categoryId);
        };

        return (
            <div className="categoryDetails" ref={ref}  onClick={handleCategoryPage}>
                <img src={categoryImage} className="categoryDetails_image" />
                <p className="categoryDetails_category">{category}</p>
            </div>
        )
    }
)
export default CategoryDetails
